// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://api.growimpact.co.uk/api`

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'app.growimpact.co.uk',
  protocol: 'https',
}

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'multi'

/**
 * Plan payments configuration.
 */
const isPlanEnabled = false
const stripePublishableKey = ''

/**
 * TinyMCE editor API key.
 */
const tinyMCEApiKey = 'oyv604zxc5jno479qh9sjtx5p54ci46evoxeyngskh99pffj'

export default {
  frontendUrl,
  backendUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  tinyMCEApiKey,
}
