import { i18n } from 'src/i18n'

class Roles {
  static get values() {
    return {
      admin: 'growImpact',
      viewUser: 'viewUser',
      manageUser: 'manageUser',
      manageGlobalSetting: 'manageGlobalSetting',
      viewAuditLog: 'viewAuditLog',
      manageOrganizationStructure: 'manageOrganizationStructure',
      manageGroup: 'manageGroup',
      createRecord: 'createRecord',
      editRecord: 'editRecord',
      viewRecord: 'viewRecord',
      deleteRecord: 'deleteRecord',
      importRecord: 'importRecord',
      manageImpactCategory: 'manageImpactCategory',
      createHistoricalRecord: 'createHistoricalRecord',
      editHistoricalRecord: 'editHistoricalRecord',
      deleteHistoricalRecord: 'deleteHistoricalRecord',
      viewPathway: 'viewPathway',
      createPathway: 'createPathway',
      editPathway: 'editPathway',
      deletePathway: 'deletePathway',
      viewConfidentialRecord: 'viewConfidentialRecord',
      changeConfidentialRecordOwner: 'changeConfidentialRecordOwner',
      evidenceTags: 'evidenceTags',
    }
  }

  static labelOf(roleId) {
    if (!this.values[roleId] && roleId !== 'growImpact') {
      return roleId
    }

    return i18n(`roles.${roleId}.label`)
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId
    }

    return i18n(`roles.${roleId}.description`)
  }
}

export default Roles
