import Roles from './roles'
import Storage from './storage'

const storage = Storage.values
const roles = Roles.values

class Permissions {
  static get values() {
    return {
      tenantCreate: {
        id: 'tenantCreate',
        allowedRoles: [roles.admin],
      },
      tenantView: {
        id: 'tenantView',
        allowedRoles: [roles.admin, roles.manageUser],
      },
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.manageUser],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.manageUser],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin, roles.manageUser],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin, roles.manageUser],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [
          roles.admin,
          roles.viewUser,
          roles.manageUser,
          roles.createRecord,
          roles.editRecord,
          roles.deleteRecord,
          roles.importRecord,
          roles.viewRecord,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin, roles.viewAuditLog],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin, roles.manageGlobalSetting],

        allowedStorage: [storage.settingsLogos],
      },
      researchTeamImport: {
        id: 'researchTeamImport',
        allowedRoles: [roles.importRecord],
      },
      researchTeamCreate: {
        id: 'researchTeamCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.researchTeamFiles, storage.researchTeamImages],
      },
      researchTeamEdit: {
        id: 'researchTeamEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.researchTeamFiles, storage.researchTeamImages],
      },
      researchTeamDestroy: {
        id: 'researchTeamDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.researchTeamFiles, storage.researchTeamImages],
      },
      researchTeamRead: {
        id: 'researchTeamRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      researchTeamAutocomplete: {
        id: 'researchTeamAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      researchThemesIntendedImpactImport: {
        id: 'researchThemesIntendedImpactImport',
        allowedRoles: [roles.importRecord],
      },
      researchThemesIntendedImpactCreate: {
        id: 'researchThemesIntendedImpactCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.researchThemesIntendedImpactFiles,
          storage.researchThemesIntendedImpactImages,
        ],
      },
      researchThemesIntendedImpactEdit: {
        id: 'researchThemesIntendedImpactEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.researchThemesIntendedImpactFiles,
          storage.researchThemesIntendedImpactImages,
        ],
      },
      researchThemesIntendedImpactDestroy: {
        id: 'researchThemesIntendedImpactDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.researchThemesIntendedImpactFiles,
          storage.researchThemesIntendedImpactImages,
        ],
      },
      researchThemesIntendedImpactRead: {
        id: 'researchThemesIntendedImpactRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      researchThemesIntendedImpactAutocomplete: {
        id: 'researchThemesIntendedImpactAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      currentProjectImport: {
        id: 'currentProjectImport',
        allowedRoles: [roles.importRecord],
      },
      currentProjectCreate: {
        id: 'currentProjectCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.currentProjectFiles,
          storage.currentProjectImages,
        ],
      },
      currentProjectEdit: {
        id: 'currentProjectEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.currentProjectFiles,
          storage.currentProjectImages,
        ],
      },
      currentProjectDestroy: {
        id: 'currentProjectDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.currentProjectFiles,
          storage.currentProjectImages,
        ],
      },
      currentProjectRead: {
        id: 'currentProjectRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      currentProjectAutocomplete: {
        id: 'currentProjectAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      collaborationPartnershipImport: {
        id: 'collaborationPartnershipImport',
        allowedRoles: [roles.importRecord],
      },
      collaborationPartnershipCreate: {
        id: 'collaborationPartnershipCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.collaborationPartnershipFiles,
          storage.collaborationPartnershipImages,
        ],
      },
      collaborationPartnershipEdit: {
        id: 'collaborationPartnershipEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.collaborationPartnershipFiles,
          storage.collaborationPartnershipImages,
        ],
      },
      collaborationPartnershipDestroy: {
        id: 'collaborationPartnershipDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.collaborationPartnershipFiles,
          storage.collaborationPartnershipImages,
        ],
      },
      collaborationPartnershipRead: {
        id: 'collaborationPartnershipRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      collaborationPartnershipAutocomplete: {
        id: 'collaborationPartnershipAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      externalDecisionMakingAdvisoryGroupImport: {
        id: 'externalDecisionMakingAdvisoryGroupImport',
        allowedRoles: [roles.importRecord],
      },
      externalDecisionMakingAdvisoryGroupCreate: {
        id: 'externalDecisionMakingAdvisoryGroupCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.externalDecisionMakingAdvisoryGroupFiles,
          storage.externalDecisionMakingAdvisoryGroupImages,
        ],
      },
      externalDecisionMakingAdvisoryGroupEdit: {
        id: 'externalDecisionMakingAdvisoryGroupEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.externalDecisionMakingAdvisoryGroupFiles,
          storage.externalDecisionMakingAdvisoryGroupImages,
        ],
      },
      externalDecisionMakingAdvisoryGroupDestroy: {
        id: 'externalDecisionMakingAdvisoryGroupDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.externalDecisionMakingAdvisoryGroupFiles,
          storage.externalDecisionMakingAdvisoryGroupImages,
        ],
      },
      externalDecisionMakingAdvisoryGroupRead: {
        id: 'externalDecisionMakingAdvisoryGroupRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      externalDecisionMakingAdvisoryGroupAutocomplete: {
        id: 'externalDecisionMakingAdvisoryGroupAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      providingExternalExpertiseImport: {
        id: 'providingExternalExpertiseImport',
        allowedRoles: [roles.importRecord],
      },
      providingExternalExpertiseCreate: {
        id: 'providingExternalExpertiseCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.providingExternalExpertiseFiles,
          storage.providingExternalExpertiseImages,
        ],
      },
      providingExternalExpertiseEdit: {
        id: 'providingExternalExpertiseEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.providingExternalExpertiseFiles,
          storage.providingExternalExpertiseImages,
        ],
      },
      providingExternalExpertiseDestroy: {
        id: 'providingExternalExpertiseDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.providingExternalExpertiseFiles,
          storage.providingExternalExpertiseImages,
        ],
      },
      providingExternalExpertiseRead: {
        id: 'providingExternalExpertiseRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      providingExternalExpertiseAutocomplete: {
        id: 'providingExternalExpertiseAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      collaborativeNetworkImport: {
        id: 'collaborativeNetworkImport',
        allowedRoles: [roles.importRecord],
      },
      collaborativeNetworkCreate: {
        id: 'collaborativeNetworkCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.collaborativeNetworkFiles,
          storage.collaborativeNetworkImages,
        ],
      },
      collaborativeNetworkEdit: {
        id: 'collaborativeNetworkEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.collaborativeNetworkFiles,
          storage.collaborativeNetworkImages,
        ],
      },
      collaborativeNetworkDestroy: {
        id: 'collaborativeNetworkDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.collaborativeNetworkFiles,
          storage.collaborativeNetworkImages,
        ],
      },
      collaborativeNetworkRead: {
        id: 'collaborativeNetworkRead',
        allowedRoles: [roles.viewRecord, roles.admin],
      },
      collaborativeNetworkAutocomplete: {
        id: 'collaborativeNetworkAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      guidelinePolicyPracticeImport: {
        id: 'guidelinePolicyPracticeImport',
        allowedRoles: [roles.importRecord],
      },
      guidelinePolicyPracticeCreate: {
        id: 'guidelinePolicyPracticeCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.guidelinePolicyPracticeFiles,
          storage.guidelinePolicyPracticeImages,
        ],
      },
      guidelinePolicyPracticeEdit: {
        id: 'guidelinePolicyPracticeEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.guidelinePolicyPracticeFiles,
          storage.guidelinePolicyPracticeImages,
        ],
      },
      guidelinePolicyPracticeDestroy: {
        id: 'guidelinePolicyPracticeDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.guidelinePolicyPracticeFiles,
          storage.guidelinePolicyPracticeImages,
        ],
      },
      guidelinePolicyPracticeRead: {
        id: 'guidelinePolicyPracticeRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      guidelinePolicyPracticeAutocomplete: {
        id: 'guidelinePolicyPracticeAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      consumerCommunityInvolvementImport: {
        id: 'consumerCommunityInvolvementImport',
        allowedRoles: [roles.importRecord],
      },
      consumerCommunityInvolvementCreate: {
        id: 'consumerCommunityInvolvementCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.consumerCommunityInvolvementFiles,
          storage.consumerCommunityInvolvementImages,
        ],
      },
      consumerCommunityInvolvementEdit: {
        id: 'consumerCommunityInvolvementEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.consumerCommunityInvolvementFiles,
          storage.consumerCommunityInvolvementImages,
        ],
      },
      consumerCommunityInvolvementDestroy: {
        id: 'consumerCommunityInvolvementDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.consumerCommunityInvolvementFiles,
          storage.consumerCommunityInvolvementImages,
        ],
      },
      consumerCommunityInvolvementRead: {
        id: 'consumerCommunityInvolvementRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      consumerCommunityInvolvementAutocomplete: {
        id: 'consumerCommunityInvolvementAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      oralPresentationImport: {
        id: 'oralPresentationImport',
        allowedRoles: [roles.importRecord],
      },
      oralPresentationCreate: {
        id: 'oralPresentationCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.oralPresentationFiles,
          storage.oralPresentationImages,
        ],
      },
      oralPresentationEdit: {
        id: 'oralPresentationEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.oralPresentationFiles,
          storage.oralPresentationImages,
        ],
      },
      oralPresentationDestroy: {
        id: 'oralPresentationDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.oralPresentationFiles,
          storage.oralPresentationImages,
        ],
      },
      oralPresentationRead: {
        id: 'oralPresentationRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      oralPresentationAutocomplete: {
        id: 'oralPresentationAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      reportImport: {
        id: 'reportImport',
        allowedRoles: [roles.importRecord],
      },
      reportCreate: {
        id: 'reportCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.reportFiles, storage.reportImages],
      },
      reportEdit: {
        id: 'reportEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.reportFiles, storage.reportImages],
      },
      reportDestroy: {
        id: 'reportDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.reportFiles, storage.reportImages],
      },
      reportRead: {
        id: 'reportRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      reportAutocomplete: {
        id: 'reportAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      toolsProductServiceImport: {
        id: 'toolsProductServiceImport',
        allowedRoles: [roles.importRecord],
      },
      toolsProductServiceCreate: {
        id: 'toolsProductServiceCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.toolsProductServiceFiles,
          storage.toolsProductServiceImages,
        ],
      },
      toolsProductServiceEdit: {
        id: 'toolsProductServiceEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.toolsProductServiceFiles,
          storage.toolsProductServiceImages,
        ],
      },
      toolsProductServiceDestroy: {
        id: 'toolsProductServiceDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.toolsProductServiceFiles,
          storage.toolsProductServiceImages,
        ],
      },
      toolsProductServiceRead: {
        id: 'toolsProductServiceRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      toolsProductServiceAutocomplete: {
        id: 'toolsProductServiceAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      awardPrizesImport: {
        id: 'awardPrizesImport',
        allowedRoles: [roles.importRecord],
      },
      awardPrizesCreate: {
        id: 'awardPrizesCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.awardPrizesFiles, storage.awardPrizesImages],
      },
      awardPrizesEdit: {
        id: 'awardPrizesEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.awardPrizesFiles, storage.awardPrizesImages],
      },
      awardPrizesDestroy: {
        id: 'awardPrizesDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.awardPrizesFiles, storage.awardPrizesImages],
      },
      awardPrizesRead: {
        id: 'awardPrizesRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      awardPrizesAutocomplete: {
        id: 'awardPrizesAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      mentoringSupervisionImport: {
        id: 'mentoringSupervisionImport',
        allowedRoles: [roles.importRecord],
      },
      mentoringSupervisionCreate: {
        id: 'mentoringSupervisionCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.mentoringSupervisionFiles,
          storage.mentoringSupervisionImages,
        ],
      },
      mentoringSupervisionEdit: {
        id: 'mentoringSupervisionEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.mentoringSupervisionFiles,
          storage.mentoringSupervisionImages,
        ],
      },
      mentoringSupervisionDestroy: {
        id: 'mentoringSupervisionDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.mentoringSupervisionFiles,
          storage.mentoringSupervisionImages,
        ],
      },
      mentoringSupervisionRead: {
        id: 'mentoringSupervisionRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      mentoringSupervisionAutocomplete: {
        id: 'mentoringSupervisionAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      caseStudyImport: {
        id: 'caseStudyImport',
        allowedRoles: [roles.importRecord],
      },
      caseStudyCreate: {
        id: 'caseStudyCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.caseStudyFiles, storage.caseStudyImages],
      },
      caseStudyEdit: {
        id: 'caseStudyEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.caseStudyFiles, storage.caseStudyImages],
      },
      caseStudyDestroy: {
        id: 'caseStudyDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.caseStudyFiles, storage.caseStudyImages],
      },
      caseStudyRead: {
        id: 'caseStudyRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      caseStudyAutocomplete: {
        id: 'caseStudyAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      communityConsumerLiteratureImport: {
        id: 'communityConsumerLiteratureImport',
        allowedRoles: [roles.importRecord],
      },
      communityConsumerLiteratureCreate: {
        id: 'communityConsumerLiteratureCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.communityConsumerLiteratureFiles,
          storage.communityConsumerLiteratureImages,
        ],
      },
      communityConsumerLiteratureEdit: {
        id: 'communityConsumerLiteratureEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.communityConsumerLiteratureFiles,
          storage.communityConsumerLiteratureImages,
        ],
      },
      communityConsumerLiteratureDestroy: {
        id: 'communityConsumerLiteratureDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.communityConsumerLiteratureFiles,
          storage.communityConsumerLiteratureImages,
        ],
      },
      communityConsumerLiteratureRead: {
        id: 'communityConsumerLiteratureRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      communityConsumerLiteratureAutocomplete: {
        id: 'communityConsumerLiteratureAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      fundingImport: {
        id: 'fundingImport',
        allowedRoles: [roles.importRecord],
      },
      fundingCreate: {
        id: 'fundingCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.fundingFiles, storage.fundingImages],
      },
      fundingEdit: {
        id: 'fundingEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.fundingFiles, storage.fundingImages],
      },
      fundingDestroy: {
        id: 'fundingDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.fundingFiles, storage.fundingImages],
      },
      fundingRead: {
        id: 'fundingRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      fundingAutocomplete: {
        id: 'fundingAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      barriersSupportImport: {
        id: 'barriersSupportImport',
        allowedRoles: [roles.importRecord],
      },
      barriersSupportCreate: {
        id: 'barriersSupportCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.barriersSupportFiles,
          storage.barriersSupportImages,
        ],
      },
      barriersSupportEdit: {
        id: 'barriersSupportEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.barriersSupportFiles,
          storage.barriersSupportImages,
        ],
      },
      barriersSupportDestroy: {
        id: 'barriersSupportDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.barriersSupportFiles,
          storage.barriersSupportImages,
        ],
      },
      barriersSupportRead: {
        id: 'barriersSupportRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      barriersSupportAutocomplete: {
        id: 'barriersSupportAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      editorialReviewerResponsibilityImport: {
        id: 'editorialReviewerResponsibilityImport',
        allowedRoles: [roles.importRecord],
      },
      editorialReviewerResponsibilityCreate: {
        id: 'editorialReviewerResponsibilityCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.editorialReviewerResponsibilityFiles,
          storage.editorialReviewerResponsibilityImages,
        ],
      },
      editorialReviewerResponsibilityEdit: {
        id: 'editorialReviewerResponsibilityEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.editorialReviewerResponsibilityFiles,
          storage.editorialReviewerResponsibilityImages,
        ],
      },
      editorialReviewerResponsibilityDestroy: {
        id: 'editorialReviewerResponsibilityDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.editorialReviewerResponsibilityFiles,
          storage.editorialReviewerResponsibilityImages,
        ],
      },
      editorialReviewerResponsibilityRead: {
        id: 'editorialReviewerResponsibilityRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      editorialReviewerResponsibilityAutocomplete: {
        id: 'editorialReviewerResponsibilityAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      educationTrainingMaterialImport: {
        id: 'educationTrainingMaterialImport',
        allowedRoles: [roles.importRecord],
      },
      educationTrainingMaterialCreate: {
        id: 'educationTrainingMaterialCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.educationTrainingMaterialFiles,
          storage.educationTrainingMaterialImages,
        ],
      },
      educationTrainingMaterialEdit: {
        id: 'educationTrainingMaterialEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.educationTrainingMaterialFiles,
          storage.educationTrainingMaterialImages,
        ],
      },
      educationTrainingMaterialDestroy: {
        id: 'educationTrainingMaterialDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.educationTrainingMaterialFiles,
          storage.educationTrainingMaterialImages,
        ],
      },
      educationTrainingMaterialRead: {
        id: 'educationTrainingMaterialRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      educationTrainingMaterialAutocomplete: {
        id: 'educationTrainingMaterialAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      interventionStudyImport: {
        id: 'interventionStudyImport',
        allowedRoles: [roles.importRecord],
      },
      interventionStudyCreate: {
        id: 'interventionStudyCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.interventionStudyFiles,
          storage.interventionStudyImages,
        ],
      },
      interventionStudyEdit: {
        id: 'interventionStudyEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.interventionStudyFiles,
          storage.interventionStudyImages,
        ],
      },
      interventionStudyDestroy: {
        id: 'interventionStudyDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.interventionStudyFiles,
          storage.interventionStudyImages,
        ],
      },
      interventionStudyRead: {
        id: 'interventionStudyRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      interventionStudyAutocomplete: {
        id: 'interventionStudyAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      inKindContributionImport: {
        id: 'inKindContributionImport',
        allowedRoles: [roles.importRecord],
      },
      inKindContributionCreate: {
        id: 'inKindContributionCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.inKindContributionFiles,
          storage.inKindContributionImages,
        ],
      },
      inKindContributionEdit: {
        id: 'inKindContributionEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.inKindContributionFiles,
          storage.inKindContributionImages,
        ],
      },
      inKindContributionDestroy: {
        id: 'inKindContributionDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.inKindContributionFiles,
          storage.inKindContributionImages,
        ],
      },
      inKindContributionRead: {
        id: 'inKindContributionRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      inKindContributionAutocomplete: {
        id: 'inKindContributionAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      mediaMentionImport: {
        id: 'mediaMentionImport',
        allowedRoles: [roles.importRecord],
      },
      mediaMentionCreate: {
        id: 'mediaMentionCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.mediaMentionFiles, storage.mediaMentionImages],
      },
      mediaMentionEdit: {
        id: 'mediaMentionEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.mediaMentionFiles, storage.mediaMentionImages],
      },
      mediaMentionDestroy: {
        id: 'mediaMentionDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.mediaMentionFiles, storage.mediaMentionImages],
      },
      mediaMentionRead: {
        id: 'mediaMentionRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      mediaMentionAutocomplete: {
        id: 'mediaMentionAutocomplete',
        allowedRoles: [roles.viewRecord],
      },
      publicationImport: {
        id: 'publicationImport',
        allowedRoles: [roles.importRecord],
      },
      publicationCreate: {
        id: 'publicationCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.publicationFiles, storage.publicationImages],
      },
      publicationEdit: {
        id: 'publicationEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.publicationFiles, storage.publicationImages],
      },
      publicationDestroy: {
        id: 'publicationDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.publicationFiles, storage.publicationImages],
      },
      publicationRead: {
        id: 'publicationRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      publicationAutocomplete: {
        id: 'publicationAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      testimonialImport: {
        id: 'testimonialImport',
        allowedRoles: [roles.importRecord],
      },
      testimonialCreate: {
        id: 'testimonialCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [storage.testimonialFiles, storage.testimonialImages],
      },
      testimonialEdit: {
        id: 'testimonialEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [storage.testimonialFiles, storage.testimonialImages],
      },
      testimonialDestroy: {
        id: 'testimonialDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [storage.testimonialFiles, storage.testimonialImages],
      },
      testimonialRead: {
        id: 'testimonialRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      testimonialAutocomplete: {
        id: 'testimonialAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      workshopTrainingFacilitatedImport: {
        id: 'workshopTrainingFacilitatedImport',
        allowedRoles: [roles.importRecord],
      },
      workshopTrainingFacilitatedCreate: {
        id: 'workshopTrainingFacilitatedCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.workshopTrainingFacilitatedFiles,
          storage.workshopTrainingFacilitatedImages,
        ],
      },
      workshopTrainingFacilitatedEdit: {
        id: 'workshopTrainingFacilitatedEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.workshopTrainingFacilitatedFiles,
          storage.workshopTrainingFacilitatedImages,
        ],
      },
      workshopTrainingFacilitatedDestroy: {
        id: 'workshopTrainingFacilitatedDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.workshopTrainingFacilitatedFiles,
          storage.workshopTrainingFacilitatedImages,
        ],
      },
      workshopTrainingFacilitatedRead: {
        id: 'workshopTrainingFacilitatedRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      workshopTrainingFacilitatedAutocomplete: {
        id: 'workshopTrainingFacilitatedAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },

      intellectualPropertyImport: {
        id: 'intellectualPropertyImport',
        allowedRoles: [roles.importRecord],
      },
      intellectualPropertyCreate: {
        id: 'intellectualPropertyCreate',
        allowedRoles: [roles.createRecord],

        allowedStorage: [
          storage.intellectualPropertyFiles,
          storage.intellectualPropertyImages,
        ],
      },
      intellectualPropertyEdit: {
        id: 'intellectualPropertyEdit',
        allowedRoles: [roles.editRecord],

        allowedStorage: [
          storage.intellectualPropertyFiles,
          storage.intellectualPropertyImages,
        ],
      },
      intellectualPropertyDestroy: {
        id: 'intellectualPropertyDestroy',
        allowedRoles: [roles.deleteRecord],

        allowedStorage: [
          storage.intellectualPropertyFiles,
          storage.intellectualPropertyImages,
        ],
      },
      intellectualPropertyRead: {
        id: 'intellectualPropertyRead',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      intellectualPropertyAutocomplete: {
        id: 'intellectualPropertyAutocomplete',
        allowedRoles: [roles.admin, roles.viewRecord],
      },
      hierarchyCreate: {
        id: 'hierarchyCreate',
        allowedRoles: [roles.admin, roles.manageOrganizationStructure],
      },
      hierarchyEdit: {
        id: 'hierarchyEdit',
        allowedRoles: [roles.admin, roles.manageOrganizationStructure],
      },
      hierarchyDestroy: {
        id: 'hierarchyDestroy',
        allowedRoles: [roles.admin, roles.manageOrganizationStructure],
      },
      hierarchyRead: {
        id: 'hierarchyRead',
        allowedRoles: [
          roles.admin,
          roles.manageOrganizationStructure,
          roles.createRecord,
          roles.editRecord,
          roles.deleteRecord,
          roles.importRecord,
          roles.viewRecord,
        ],
      },
      groupCreate: {
        id: 'groupCreate',
        allowedRoles: [roles.admin, roles.manageGroup],
      },
      groupEdit: {
        id: 'groupEdit',
        allowedRoles: [roles.admin, roles.manageGroup],
      },
      groupDestroy: {
        id: 'groupDestroy',
        allowedRoles: [roles.admin, roles.manageGroup],
      },
      groupRead: {
        id: 'groupRead',
        allowedRoles: [roles.admin, roles.manageGroup],
      },
      groupAssign: {
        id: 'groupAssign',
        allowedRoles: [roles.admin, roles.manageGroup],
      },
      impactCategoryManage: {
        id: 'impactCategoryManage',
        allowedRoles: [roles.admin, roles.manageImpactCategory],
      },
      impactCategoryRead: {
        id: 'impactCategoryRead',
        allowedRoles: [
          roles.admin,
          roles.viewUser,
          roles.manageUser,
          roles.manageGlobalSetting,
          roles.viewAuditLog,
          roles.manageOrganizationStructure,
          roles.manageGroup,
          roles.createRecord,
          roles.viewRecord,
          roles.deleteRecord,
          roles.importRecord,
          roles.manageImpactCategory,
        ],
      },
      historicalRecordCreate: {
        id: 'historicalRecordCreate',
        allowedRoles: [roles.admin, roles.createHistoricalRecord],
      },
      historicalRecordEdit: {
        id: 'historicalRecordEdit',
        allowedRoles: [roles.admin, roles.editHistoricalRecord],
      },
      historicalRecordDelete: {
        id: 'historicalRecordDelete',
        allowedRoles: [roles.admin, roles.deleteHistoricalRecord],
      },
      integrationCreate: {
        id: 'integrationCreate',
        allowedRoles: [roles.admin],
      },
      integrationEdit: {
        id: 'integrationEdit',
        allowedRoles: [roles.admin],
      },
      integrationDestroy: {
        id: 'integrationDestroy',
        allowedRoles: [roles.admin],
      },
      integrationRead: {
        id: 'integrationRead',
        allowedRoles: [roles.admin],
      },
      pathwayView: {
        id: 'pathwayView',
        allowedRoles: [roles.admin, roles.viewPathway],
      },
      pathwayCreate: {
        id: 'pathwayCreate',
        allowedRoles: [roles.admin, roles.createPathway],
      },
      pathwayEdit: {
        id: 'pathwayEdit',
        allowedRoles: [roles.admin, roles.editPathway],
      },
      pathwayDelete: {
        id: 'pathwayDelete',
        allowedRoles: [roles.admin, roles.deletePathway],
      },
      evidenceTags: {
        id: 'evidenceTags',
        allowedRoles: [roles.admin, roles.evidenceTags],
      },
    }
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value]
    })
  }
}

export default Permissions
