export enum TenantMode {
  Business = 'business',
  Research = 'research',
}

export enum TenantType {
  individualSubscription = 'individualSubscription',
  default = 'default',
  user = 'user',
}
