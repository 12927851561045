import { createSelector } from 'reselect'
import authSelectors from 'src/modules/auth/authSelectors'
import PermissionChecker from 'src/modules/auth/permissionChecker'
import Permissions from 'src/security/permissions'

const selectPermissionToViewPathway = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) =>
    new PermissionChecker(currentTenant, currentUser).match(
      Permissions.values.pathwayView,
    ),
)

const selectPermissionToCreatePathway = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => {
    return new PermissionChecker(currentTenant, currentUser).match(
      Permissions.values.pathwayCreate,
    )
  },
)

const selectPermissionToEditPathway = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) =>
    new PermissionChecker(currentTenant, currentUser).match(
      Permissions.values.pathwayEdit,
    ),
)

const selectPermissionToDeletePathway = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) =>
    new PermissionChecker(currentTenant, currentUser).match(
      Permissions.values.pathwayDelete,
    ),
)

const selectEvidenceTagsPermission = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) =>
    new PermissionChecker(currentTenant, currentUser).match(
      Permissions.values.evidenceTags,
    ),
)

const permissionSelectors = {
  selectPermissionToViewPathway,
  selectPermissionToCreatePathway,
  selectPermissionToEditPathway,
  selectPermissionToDeletePathway,
  selectEvidenceTagsPermission,
}

export default permissionSelectors
