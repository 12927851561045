import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { TenantMode, TenantType } from 'src/helpers/types/TenantModes'
import authSelectors from 'src/modules/auth/authSelectors'
import Roles from 'src/security/roles'

function useMenuItemVisibility() {
  const { pathname } = useLocation()
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const currentUser = useSelector(authSelectors.selectCurrentUser)

  const currentTenantUser = currentUser.tenants.find(
    (tenant) => tenant.tenantId === currentTenant.id,
  )

  const isBusinessMode = useMemo(
    () => currentTenant?.mode === TenantMode.Business,
    [currentTenant],
  )

  const isRefMode = useMemo(
    () => currentTenant?.mode === TenantMode.Research,
    [currentTenant],
  )

  const isIndividualSubscriptionType = useMemo(
    () => currentTenant?.type === TenantType.individualSubscription,
    [currentTenant],
  )

  const adminRoles = useMemo(
    () => [
      Roles.values.admin,
      Roles.values.manageUser,
      Roles.values.manageGlobalSetting,
      Roles.values.manageOrganizationStructure,
      Roles.values.manageGroup,
      Roles.values.manageImpactCategory,
    ],
    [],
  )

  const notCollectPaths = useMemo(
    () => [
      '/admin',
      '/map',
      '/report',
      '/profile',
      '/password-change',
      '/tenant',
    ],
    [],
  )

  const isCollect = useMemo(
    () => !notCollectPaths.some((path) => pathname.startsWith(path)),
    [notCollectPaths, pathname],
  )

  const isReport = useMemo(() => pathname.startsWith('/report'), [pathname])
  const isAdmin = useMemo(() => pathname.startsWith('/admin'), [pathname])

  const showAdmin = useMemo(
    () => currentTenantUser.roles.some((role) => adminRoles.includes(role)),
    [adminRoles, currentTenantUser.roles],
  )

  return {
    isBusinessMode,
    isCollect,
    showAdminMenuItem: showAdmin && isBusinessMode,
    showAdminInProfile: showAdmin && !isBusinessMode,
    isReport,
    isAdmin,
    isRefMode,
    isIndividualSubscriptionType,
  }
}

export default useMenuItemVisibility
