import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class OrganisationStructureService {
  static async edit(id, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/hierarchies/${id}`,
      body,
    )
    return response.data
  }

  static async destroy(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/hierarchies/${id}`,
    )

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/hierarchies`,
      body,
    )

    return response.data
  }

  static async get(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchies/${id}`,
    )
    return response.data
  }

  static async getAll(archived: boolean, includeIndividual: boolean) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchies?archived=${archived}&includeIndividual=${includeIndividual}`,
    )
    return response.data
  }

  static async getValues(id, archived = false) {
    const params = {
      filter: { archived: archived },
    }
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchies/${id}/values`,
      {
        params,
      },
    )
    return response.data
  }

  static async getFlatHierarchies(archived = false, includeIndividual = true) {
    const params = {
      filter: { archived, includeIndividual },
    }
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/flat-hierarchies`,
      {
        params,
      },
    )
    return response.data.hierarchies
  }

  static async searchLowestHierarchy(query) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/search/lowest-hierarchy?q=${query}`,
    )

    return response.data
  }

  static async getFlatHierarchyUnits(archived = false) {
    const params = {
      filter: { archived: archived },
    }
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchy-units`,
      {
        params,
      },
    )

    return response.data
  }
}
