import organisationStructureService from 'src/modules/admin/OrganisationStructure/OrganisationStructureService'
import { flattenArray } from 'src/helpers/functions'

const prefix = `RECORD_SECURITY`

const recordSecurityActions = {
  ADD_FLAT_HIERARCHIES: `${prefix}_ADD_FLAT_HIERARCHIES`,
  ADD_ACTIVE_HIERARCHY_VALUES: `${prefix}_ADD_ACTIVE_HIERARCHY_VALUES`,
  SELECT_HIERARCHY_VALUE: `${prefix}_SELECT_HIERARCHY_VALUE`,
  REMOVE_HIERARCHY_VALUE: `${prefix}_REMOVE_HIERARCHY_VALUE`,
  CLEAR_ALL: `${prefix}_CLEAR_ALL`,
  VALIDATE_ALL: `${prefix}_VALIDATE_ALL`,

  doSetHierarchyValues:
    (flatHierarchies: any[], user?: any, record?: any, moduleName?: string) =>
    async (dispatch) => {
      let hierarchyWithValues = await Promise.all(
        flatHierarchies.map(async (flatHierarchy) => {
          const hierarchyValuesPayload =
            await organisationStructureService.getValues(flatHierarchy.id)
          flatHierarchy.hierarchyValues = hierarchyValuesPayload.rows
          flatHierarchy.selectedHierarchyValue = null
          flatHierarchy.removed = true
          flatHierarchy.banned = true
          flatHierarchy.activeHierarchies = []

          if (flatHierarchy.depth === 0) {
            flatHierarchy.activeHierarchies = hierarchyValuesPayload.rows
            flatHierarchy.selectedHierarchyValue = null
            flatHierarchy.removed = true
            flatHierarchy.banned = false
            flatHierarchy.softValid = false
            flatHierarchy.valid = false
          }

          if (flatHierarchy.depth > 0) {
            if (flatHierarchy.mandatory) {
              flatHierarchy.softValid = true
              flatHierarchy.valid = true
            } else {
              flatHierarchy.softValid = true
              flatHierarchy.valid = true
            }
          }

          return Promise.resolve(flatHierarchy)
        }),
      )

      if (user && user.orgStructure && user.orgStructure.length > 0) {
        const flatOrgStructure = flattenArray(user.orgStructure)
        hierarchyWithValues = hierarchyWithValues.map((hierarchy) => {
          const restrictedHierarchyValues = hierarchy.hierarchyValues.filter(
            (hierarchyValue) => {
              return Boolean(
                flatOrgStructure.find(
                  (orgStructure) => orgStructure.id === hierarchyValue.id,
                ),
              )
            },
          )
          if (restrictedHierarchyValues.length > 0) {
            hierarchy.hierarchyValues = restrictedHierarchyValues
          }
          return hierarchy
        })
      }
      if (
        record &&
        record.recordHierarchies &&
        record.recordHierarchies.length > 0
      ) {
        let parentHierarchyDepth = 0
        let parentId = 0
        hierarchyWithValues = hierarchyWithValues.map((hierarchy) => {
          const recordHierarchy = record.recordHierarchies.find(
            (recordHierarchy) => {
              return recordHierarchy.hierarchy.id === hierarchy.id
            },
          )

          if (recordHierarchy && !hierarchy.selectedHierarchyValue) {
            if (hierarchy.depth > parentHierarchyDepth) {
              parentHierarchyDepth = hierarchy.depth
              parentId = recordHierarchy.parent && recordHierarchy.parent.id
            }
            hierarchy.banned = false
            hierarchy.removed = false
            if (hierarchy.mandatory) {
              hierarchy.softValid = true
              hierarchy.valid = true
            }
            if (recordHierarchy && !recordHierarchy.archived) {
              hierarchy.selectedHierarchyValue = {
                id: recordHierarchy.id,
                value:
                  recordHierarchy[moduleName + 'HierarchyValues']
                    .hierarchyValueId,
                hierarchyValue: { name: recordHierarchy.name },
              }
            }

            if (recordHierarchy.parent)
              hierarchy.activeHierarchies = hierarchy.hierarchyValues.filter(
                (hierarchyValue) =>
                  hierarchyValue.parentId === recordHierarchy.parent.id,
              )
          }
          return hierarchy
        })

        hierarchyWithValues = hierarchyWithValues.map((hierarchy, index) => {
          if (hierarchy.depth === parentHierarchyDepth + 1) {
            parentId =
              hierarchyWithValues[index > 0 ? index - 1 : 0]
                ?.selectedHierarchyValue?.id
            hierarchy.removed = true
            hierarchy.banned = false
            hierarchy.activeHierarchies = hierarchy.hierarchyValues.filter(
              (hierarchyValue) => hierarchyValue.parentId === parentId,
            )
          }
          return hierarchy
        })
      }

      if (
        (!record || Object.keys(record).length === 0) &&
        user &&
        user.userHierarchies &&
        user.userHierarchies.length > 0
      ) {
        let parentHierarchyDepth = 0
        let parentId = 0
        hierarchyWithValues = hierarchyWithValues.map((hierarchy) => {
          const userHierarchy = user.userHierarchies.find((userHierarchy) => {
            return userHierarchy.hierarchyValue.hierarchyId === hierarchy.id
          })
          if (userHierarchy && !hierarchy.selectedHierarchyValue) {
            if (hierarchy.depth > parentHierarchyDepth) {
              parentHierarchyDepth = hierarchy.depth
              parentId = userHierarchy.hierarchyValue.id
            }
            hierarchy.banned = false
            hierarchy.removed = false
            if (hierarchy.mandatory) {
              hierarchy.softValid = true
              hierarchy.valid = true
            }
            if (!userHierarchy.hierarchyValue.archived) {
              hierarchy.selectedHierarchyValue = {
                value: userHierarchy.hierarchyValueId,
                hierarchyValue: userHierarchy.hierarchyValue,
              }
            }

            hierarchy.activeHierarchies = hierarchy.hierarchyValues.filter(
              (hierarchyValue) =>
                hierarchyValue.parentId ===
                userHierarchy.hierarchyValue.parentId,
            )
          }
          return hierarchy
        })

        hierarchyWithValues = hierarchyWithValues.map((hierarchy) => {
          if (hierarchy.depth === parentHierarchyDepth + 1) {
            hierarchy.removed = true
            hierarchy.banned = false
            hierarchy.activeHierarchies = hierarchy.hierarchyValues.filter(
              (hierarchyValue) => hierarchyValue.parentId === parentId,
            )
          }
          return hierarchy
        })
      }

      const topHierarchyWithValue = hierarchyWithValues.find(
        (hierarchyWithValue) => hierarchyWithValue.depth === 0,
      )

      dispatch({
        type: recordSecurityActions.ADD_FLAT_HIERARCHIES,
        payload: { [topHierarchyWithValue.id]: hierarchyWithValues },
      })
    },
  doSelectHierarchyValue:
    (hierarchyWithValue: {
      key: any
      hierarchy: any
      hierarchyValue: any
      edit?: boolean
    }) =>
    (dispatch) => {
      dispatch({
        type: recordSecurityActions.SELECT_HIERARCHY_VALUE,
        payload: hierarchyWithValue,
      })
    },
  doRemoveHierarchyValue:
    (hierarchyWithValue: { key: any; hierarchy: any }) => (dispatch) => {
      dispatch({
        type: recordSecurityActions.REMOVE_HIERARCHY_VALUE,
        payload: hierarchyWithValue,
      })
    },
  doClear: () => (dispatch) => {
    dispatch({ type: recordSecurityActions.CLEAR_ALL })
  },

  doValidateAll: () => (dispatch) => {
    dispatch({ type: recordSecurityActions.VALIDATE_ALL })
  },
}

export default recordSecurityActions
