import authSelectors from 'src/modules/auth/authSelectors'
import layoutSelectors from 'src/modules/layout/layoutSelectors'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import PrivateRoute from 'src/routes/components/PrivateRoute'
import PublicRoute from 'src/routes/components/PublicRoute'
import EmptyPermissionsRoute from 'src/routes/components/EmptyPermissionsRoute'
import IdleTimer from 'react-idle-timer'
import authActions from 'src/modules/auth/authActions'
import Permissions from '../security/permissions'
import { useLocation } from 'react-router'
import ProgressBar from './components/ProgressBar'
import CustomLoadable from './components/customLoadable'
import { useSso } from '../modules/auth/ssoHooks'
import SsoRedirectedPage from '../pages/auth/SsoRedirectedPage'
import useRoutes from './components/useRoutes'
import AdminRoute from './components/AdminRoute'
import SimpleRoute from './components/SimpleRoute'

function getPathToRedirect(currentTenantMode, currentUser) {
  if (!currentUser) {
    return '/auth/signin'
  }

  if (currentTenantMode === 'research') {
    return '/map'
  }

  return '/publication'
}

function RoutesComponent() {
  const routes = useRoutes()
  const isInitialMount = useRef(true)
  const location = useLocation()
  const dispatch = useDispatch()
  const { isLoading } = useSso()

  const authLoading = useSelector(authSelectors.selectLoadingInit)
  const layoutLoading = useSelector(layoutSelectors.selectLoading)
  const currentUser = useSelector(authSelectors.selectCurrentUser)
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const logout = useSelector(authSelectors.selectLogoutAction)
  const logoutPath = useSelector(authSelectors.selectLogoutPath)

  const redirectTo = new URLSearchParams(location.search).get('redirect')
  const loading = authLoading || layoutLoading || isLoading

  const currentUserRoles = useMemo(() => {
    if (currentTenant && currentUser) {
      return currentUser.tenants.find(
        (item) => item.tenant.id === currentTenant.id,
      ).roles
    }
    return []
  }, [currentTenant, currentUser])

  const handleOnIdle = () => {
    dispatch(authActions.doSignout())
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      ProgressBar.start()
      return
    }

    if (!loading) {
      ProgressBar.done()
    }
  }, [loading])

  if (loading) {
    return <div />
  }

  return (
    <>
      <IdleTimer
        timeout={1000 * 60 * 60}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <Routes>
        {routes.privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute
                currentUser={currentUser}
                currentTenant={currentTenant}
                permissionRequired={route.permissionRequired}
                path={route.path}
                logoutAction={logout}
                component={CustomLoadable({
                  loader: route.loader,
                })}
                exact={Boolean(route.exact)}
                fullLayout={route.fullLayout || false}
                logoutPath={logoutPath}
              />
            }
          />
        ))}
        <Route
          path={'/pathway-download/:id'}
          element={
            <PrivateRoute
              key={'/pathway-download/:idd'}
              currentUser={currentUser}
              currentTenant={currentTenant}
              permissionRequired={Permissions.values.pathwayView}
              path={'/pathway-download/:id'}
              logoutAction={logout}
              component={CustomLoadable({
                loader: () =>
                  import('../pages/map/common/PathwayUpdater/PathwayExporter'),
              })}
              exact={true}
              logoutPath={logoutPath}
            />
          }
        />
        <Route
          path={'/files/:id/download'}
          element={
            <PrivateRoute
              key={'/files/:id/download'}
              currentUser={currentUser}
              currentTenant={currentTenant}
              permissionRequired={Permissions.values.reportRead}
              path={'/files/:id/download'}
              logoutAction={logout}
              component={CustomLoadable({
                loader: () => import('../pages/file/FileDownload'),
              })}
              exact={true}
              logoutPath={logoutPath}
            />
          }
        />

        <Route
          path="/"
          element={
            <Navigate
              to={getPathToRedirect(currentTenant?.mode, currentUser)}
            />
          }
        />
        <Route path="/auth" element={<SsoRedirectedPage />} />
        <Route
          path="/admin"
          element={<AdminRoute currentUserRoles={currentUserRoles} />}
        />

        {routes.publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute
                key={route.path}
                exact
                path={route.path}
                currentUser={currentUser}
                currentTenant={currentTenant}
                redirectPath={redirectTo || '/'}
                component={CustomLoadable({
                  loader: route.loader,
                })}
              />
            }
          />
        ))}

        {routes.emptyPermissionsRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <EmptyPermissionsRoute
                key={route.path}
                exact
                path={route.path}
                currentUser={currentUser}
                currentTenant={currentTenant}
                component={CustomLoadable({
                  loader: route.loader,
                })}
              />
            }
          />
        ))}

        {routes.simpleRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <SimpleRoute
                key={route.path}
                exact
                path={route.path}
                component={CustomLoadable({
                  loader: route.loader,
                })}
              />
            }
          />
        ))}
      </Routes>
    </>
  )
}

export default RoutesComponent
