import { createSelector } from 'reselect'

const selectRaw = (state) => state.impactCategories.list

const selectLoading = createSelector([selectRaw], (raw) => raw.loading)

const selectRows = createSelector([selectRaw], (raw) => raw.rows)

const selectFilteredRows = createSelector([selectRaw], (raw) =>
  raw.rows.filter(
    (item) => !['currentProject', 'funding'].includes(item.impactCategory),
  ),
)

const selectMenuItems = createSelector([selectRaw], (raw) => raw.menuItems)

const selectCount = createSelector([selectRaw], (raw) => raw.count)

const selectHasRows = createSelector([selectCount], (count) => count > 0)

const selectImpactCategoryIds = createSelector(
  [selectRaw],
  (raw) => raw.impactCategoryIds,
)

const impactCategoriesListSelectors = {
  selectLoading,
  selectRows,
  selectCount,
  selectHasRows,
  selectMenuItems,
  selectImpactCategoryIds,
  selectFilteredRows,
}

export default impactCategoriesListSelectors
