import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authSelectors from 'src/modules/auth/authSelectors'
import config from 'src/config'
import { io } from 'socket.io-client'
import authActions from 'src/modules/auth/authActions'
import Message from 'src/components/message'

const SocketContext = createContext<any>(null)
const url = new URL(config.backendUrl)
url.pathname = '/' // Reset the path to root
const SOCKET_URL = url.toString().replace(/\/$/, '')

export const SocketProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<any>(null)
  const currentUser = useSelector(authSelectors.selectCurrentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    let newSocket: any = null

    if (currentUser?.id) {
      newSocket = io(SOCKET_URL, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 5000,
      })

      // Connection event
      newSocket.on('connect', () => {
        console.log('socket connected', currentUser?.id)
        newSocket.emit('register', currentUser?.id)
      })

      // Disconnection event
      newSocket.on('disconnect', () => {
        console.log('Socket Disconnected')
      })

      // Connection error
      newSocket.on('connect_error', (error) => {
        console.error('Socket Connection Error', error)
      })

      // Reconnection event
      newSocket.on('reconnect', (attemptNumber) => {
        console.log('Socket reconnected after', attemptNumber, 'attempts')
      })

      newSocket.on('cancel_subscription', (userId) => {
        if (userId === currentUser?.id) {
          dispatch(authActions.doSignout())
          Message.error('Your Subscription was cancelled')
        }
      })

      setSocket(newSocket)
    }

    if (!currentUser?.id) {
      newSocket?.disconnect()
    }

    // Cleanup on component unmount
    return () => {
      newSocket?.disconnect()
    }
  }, [currentUser?.id])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider')
  }
  return context
}
