import usePrivateRoutes from './usePrivateRoutes'

const useRoutes = () => {
  const privateRoutes = usePrivateRoutes()
  const publicRoutes = [
    {
      path: '/auth/signin/:tenant?',
      loader: () => import('src/pages/auth/SignInPage'),
    },
    {
      path: '/auth/forgot-password',
      loader: () => import('src/pages/auth/ForgotPasswordPage'),
    },
  ].filter(Boolean)

  const emptyPermissionsRoutes = [
    {
      path: '/auth/empty-permissions',
      loader: () => import('src/pages/auth/EmptyPermissionsPage'),
    },
  ].filter(Boolean)

  const simpleRoutes = [
    {
      path: '/auth/initial-password',
      loader: () => import('src/pages/auth/setPasswordPage'),
    },
    {
      path: '/auth/password-reset',
      loader: () => import('src/pages/auth/PasswordResetPage'),
    },
    {
      path: '/payment/step-1',
      loader: () => import('src/pages/payment'),
    },
    {
      path: '/payment/success',
      loader: () => import('src/pages/payment/success'),
    },
    {
      path: '/payment/failure',
      loader: () => import('src/pages/payment/failure'),
    },
    {
      path: '*',
      loader: () => import('src/pages/error'),
    },
  ].filter(Boolean)

  return {
    privateRoutes,
    publicRoutes,
    emptyPermissionsRoutes,
    simpleRoutes,
  }
}

export default useRoutes
