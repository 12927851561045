import recordSecurityActions from 'src/modules/security/recordSecurityActions'

const initialState = {
  flatHierarchies: {},
  validation: false,
}

export default function (state = initialState, { type, payload }) {
  if (type === recordSecurityActions.ADD_FLAT_HIERARCHIES) {
    return {
      ...state,
      flatHierarchies: { ...state.flatHierarchies, ...payload },
    }
  }

  if (type === recordSecurityActions.SELECT_HIERARCHY_VALUE) {
    const hierarchies = state.flatHierarchies[payload.key]
    const hierarchiesWithValues = hierarchies.map((hierarchy) => {
      if (hierarchy.id === payload.hierarchy.id) {
        hierarchy.banned = false
        hierarchy.removed = false
        if (hierarchy.mandatory) {
          hierarchy.valid = true
          hierarchy.softValid = true
        }
        if (payload.edit) {
          if (hierarchy.selectedHierarchyValue) {
            hierarchy.selectedHierarchyValue.value = payload.hierarchyValue.id
          } else {
            hierarchy.selectedHierarchyValue = {
              id: null,
              value: payload.hierarchyValue.id,
              hierarchyValue: payload.hierarchyValue,
            }
          }
        } else {
          hierarchy.selectedHierarchyValue = {
            value: payload.hierarchyValue.id,
            hierarchyValue: payload.hierarchyValue,
          }
        }
      }

      if (payload.hierarchy.depth + 1 === hierarchy.depth) {
        hierarchy.removed = true
        hierarchy.banned = false
        hierarchy.activeHierarchies = hierarchy.hierarchyValues.filter(
          (hierarchyValue) =>
            hierarchyValue.parentId === payload.hierarchyValue.id,
        )
      }

      return hierarchy
    })
    return {
      ...state,
      flatHierarchies: {
        ...state.flatHierarchies,
        [payload.key]: hierarchiesWithValues,
      },
    }
  }

  if (type === recordSecurityActions.REMOVE_HIERARCHY_VALUE) {
    const hierarchies = state.flatHierarchies[payload.key]
    const hierarchiesWithValues = hierarchies.map((hierarchy) => {
      if (hierarchy.id === payload.hierarchy.id) {
        if (hierarchy.mandatory && hierarchy.depth === 0) {
          hierarchy.softValid = false
          hierarchy.valid = false
        }
        hierarchy.banned = false
        hierarchy.removed = true
        hierarchy.selectedHierarchyValue = null
      }

      if (payload.hierarchy.depth < hierarchy.depth) {
        hierarchy.banned = true
        hierarchy.removed = true
        hierarchy.selectedHierarchyValue = null
        hierarchy.softValid = true
        hierarchy.valid = true
      }

      return hierarchy
    })

    return {
      ...state,
      flatHierarchies: {
        ...state.flatHierarchies,
        [payload.key]: hierarchiesWithValues,
      },
    }
  }

  if (type === recordSecurityActions.VALIDATE_ALL) {
    let updatedFlatHierarchies = {}
    let validationArray = []
    const flatHierarchies = state.flatHierarchies
    Object.keys(flatHierarchies).forEach((flatHierarchyKey) => {
      updatedFlatHierarchies = {
        ...updatedFlatHierarchies,
        [flatHierarchyKey]: flatHierarchies[flatHierarchyKey].map(
          (flatHierarchy) => {
            validationArray.push(flatHierarchy.softValid)
            if (!flatHierarchy.softValid) {
              flatHierarchy.valid = false
            }
            return flatHierarchy
          },
        ),
      }
    })

    return {
      ...state,
      flatHierarchies: updatedFlatHierarchies,
      validation: validationArray,
    }
  }

  if (type === recordSecurityActions.CLEAR_ALL) {
    return { ...state, flatHierarchies: {} }
  }

  return state
}
