import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { i18n } from 'src/i18n'
import permissions from 'src/pages/map/permissionSelectors'
import useMenuItemVisibility from 'src/hooks/useMenuItemVisibility'

const NavItem = (props) => (
  <li>
    {props.count ? <span className="badge">{props.count}+</span> : null}
    <NavLink
      to={props.link}
      className={`nav-link text-uppercase ${props.customStyle}`}
    >
      {i18n(`header.navItems.${props.name}.label`)}
    </NavLink>
  </li>
)

export default ({ currentUser }) => {
  const hasPermissionToViewPathway = useSelector(
    permissions.selectPermissionToViewPathway,
  )

  const {
    isBusinessMode,
    showAdminMenuItem,
    isCollect,
    isReport,
    isAdmin,
    isRefMode,
  } = useMenuItemVisibility()

  return (
    <ul className="navbar-nav mx-auto">
      {showAdminMenuItem ? (
        <NavItem name="admin" link="/admin" customStyle="font-weight-bold" />
      ) : null}

      {isBusinessMode ? (
        <NavItem
          name="map"
          link="/map"
          customStyle={
            hasPermissionToViewPathway
              ? 'font-weight-bold'
              : 'font-weight-bold disabled'
          }
        />
      ) : null}

      {isBusinessMode || isReport || isAdmin ? (
        <NavItem
          name="collect"
          link="/publication"
          customStyle="font-weight-bold"
        />
      ) : null}

      {isRefMode && isAdmin ? (
        <NavItem name="project" link="/map" customStyle="font-weight-bold" />
      ) : null}

      {isBusinessMode || isCollect ? (
        <NavItem name="report" link="/report" customStyle="font-weight-bold" />
      ) : null}
    </ul>
  )
}
