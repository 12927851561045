import Importer from 'src/modules/shared/importer/importer'
import { i18n } from 'src/i18n'
import Errors from 'src/modules/shared/error/errors'
import chunk from 'lodash/chunk'
import md5 from 'md5'
import moment from 'moment'
import { v4 as uuid4 } from 'uuid'

function hasRecordSecurityError(values) {
  for (let i = 0; i < values.length - 1; i++) {
    if (values[i] === false && values[i + 1] === true) {
      return true
    }
  }
  return false
}

function transformHierarchyRow(row, fields) {
  let canLogin = false
  const userHierarchy = []
  const hierarchyFields = fields.filter((field) => field.hierarchy)
  const optionFields = fields.filter((field) => field.optionField)
  const multiSelectFields = fields.filter((field) => field.multiSelectField)
  const mileStoneFields = fields.filter((field) => field.milestonesField)
  const endDateField = fields.find((field) => field.name === 'endDate')

  Object.keys(row).forEach((rowIndex) => {
    const hierarchyField = hierarchyFields.find(
      (hierarchyField) =>
        rowIndex.trim().toLowerCase() ===
        hierarchyField.name.trim().toLowerCase(),
    )
    if (hierarchyField) {
      const hierarchyFieldValue = hierarchyField.values.find(
        (hierarchyValue) =>
          row[rowIndex] === hierarchyValue.name.trim().toLowerCase(),
      )
      userHierarchy.push(hierarchyFieldValue.name)
      delete row[rowIndex]
    }

    const optionField = optionFields.find((option) => rowIndex === option.name)
    const multiSelectField = multiSelectFields.find(
      (option) => rowIndex === option.name,
    )

    if (optionField) {
      const { options } = optionField

      if (options && options.length > 0) {
        if (Array.isArray(row[rowIndex]) && row[rowIndex].length > 0) {
          let optionValue = []
          row[rowIndex].forEach((rowIndexValue) => {
            const option = options.find(
              (val) => val.label.trim().toLowerCase() === rowIndexValue,
            )
            optionValue = [...optionValue, option.id]
          })
          row[rowIndex] = optionValue
        }
        const optionValue = options.find(
          (val) => val.label.trim().toLowerCase() === row[rowIndex],
        )
        if (optionValue) {
          row[rowIndex] = optionValue.id
          if (rowIndex === 'eductionTrainingMaterial') {
            row['educationTrainingMaterialId'] = optionValue.id
          }
        }
      }
    }

    if (multiSelectField) {
      const { options } = multiSelectField
      let optionValue = []

      if (
        options &&
        options.length > 0 &&
        Array.isArray(row[rowIndex]) &&
        row[rowIndex].length > 0
      ) {
        row[rowIndex].forEach((rowIndexValue) => {
          const option = options.find(
            (val) => val.label.trim().toLowerCase() === rowIndexValue,
          )
          optionValue = [...optionValue, option.id]
        })
      }
      row[rowIndex] = optionValue
    }

    const milestoneField = mileStoneFields.find(
      (option) => rowIndex === option.name,
    )

    if (milestoneField) {
      row[rowIndex] = JSON.parse(row[rowIndex])
      if (Array.isArray(row[rowIndex]) && row[rowIndex].length > 0) {
        const values = row[rowIndex].map((value, index) => {
          return {
            id: uuid4(),
            title: value,
            completed: false,
            order: index + 1,
          }
        })
        row.fundingMilestones = values
      }
    }
  })

  const recordSecurity = []
  const recordSecurityFields = fields.filter((field) => field.recordSecurity)
  if (recordSecurityFields.length > 1) {
    const valuesExceptParent = recordSecurityFields
      .slice(0, recordSecurityFields.length - 1)
      .map((item) => Boolean(row[item.name]))
      .reverse()

    const hasError = hasRecordSecurityError(valuesExceptParent)

    if (hasError) {
      throw new Error('Record Security is not valid')
    }
  }

  recordSecurityFields.forEach((recordSecurityField) => {
    if (row[recordSecurityField.name]) {
      const rowValue = row[recordSecurityField.name]
      const originalValuePayload = recordSecurityField.values.find(
        (recordSecurityFieldValue) =>
          recordSecurityFieldValue.name.trim().toLowerCase() === rowValue,
      )
      recordSecurity.push(originalValuePayload.id)
    }
  })

  if (row.groups && row.groups.length > 0) {
    canLogin = true
  }

  row.canLogin = canLogin
  row.userHierarchy = userHierarchy
  row.recordHierarchies = recordSecurity

  if (!row.groups) {
    row.groups = []
  }

  const involvedField = fields.find((field) => field.involvedField)
  const individualsInvolvedField = fields.find(
    (field) => field.individualsInvolvedField,
  )

  if (individualsInvolvedField) {
    const individualsInvolved = []

    if (row['Involved Researchers'] && row['Involved Role']) {
      const involvedResearchers = row['Involved Researchers']
      const involvedRoles = row['Involved Role']
      const otherRoles = row['Other Roles']
      const responsibilities = row['Responsibilities']

      involvedResearchers.forEach((involvedResearcher, index) => {
        const involvedResearcherPayload = individualsInvolvedField.users.find(
          (user) =>
            user.displayName.trim().toLowerCase() === involvedResearcher ||
            user.fullName.trim().toLowerCase() === involvedResearcher,
        )
        const involvedRolePayload = individualsInvolvedField.options.find(
          (option) =>
            option.label.trim().toLowerCase() === involvedRoles[index],
        )

        const otherRolePayload =
          otherRoles[index] !== 'null' ? otherRoles[index] : null
        const responsibilityPayload =
          responsibilities[index] !== 'null' ? responsibilities[index] : null
        const individual = {
          id: involvedResearcherPayload.id,
          role: involvedRolePayload.id,
          otherRole: otherRolePayload,
          responsibility: responsibilityPayload,
        }
        individualsInvolved.push(individual)
      })
      row.individualsInvolved = individualsInvolved

      delete row['Involved Researchers']
      delete row['Involved Role']
      delete row['Responsibility']
    }
  } else if (involvedField) {
    const researchers = []
    if (row['Involved Researchers'] && row['Involved Role']) {
      const involvedResearchers = row['Involved Researchers']
      const involvedRoles = row['Involved Role']
      const otherRoles = row['Other Roles']

      involvedResearchers.forEach((involvedResearcher, index) => {
        const involvedResearcherPayload = involvedField.users.find(
          (user) =>
            user.displayName.trim().toLowerCase() === involvedResearcher ||
            user.fullName.trim().toLowerCase() === involvedResearcher,
        )
        const involvedRolePayload = involvedField.options.find(
          (option) =>
            option.label.trim().toLowerCase() === involvedRoles[index],
        )

        const otherRolePayload =
          otherRoles[index] !== 'null' ? otherRoles[index] : null
        const researcher = {
          id: involvedResearcherPayload.id,
          role: involvedRolePayload.id,
          otherRole: otherRolePayload,
        }
        researchers.push(researcher)
      })

      delete row['Involved Researchers']
      delete row['Involved Role']
    } else if (row['Involved Researchers']) {
      const involvedResearchers = row['Involved Researchers']

      involvedResearchers.forEach((involvedResearcher) => {
        const involvedResearcherPayload = involvedField.users.find(
          (user) =>
            user.displayName.trim().toLowerCase() === involvedResearcher ||
            user.fullName.trim().toLowerCase() === involvedResearcher,
        )
        researchers.push(involvedResearcherPayload.id)
      })

      delete row['Involved Researchers']
    }

    row.researchers = researchers
  }

  const workshopOrganizationField = fields.find(
    (field) => field.workshopOrganizationField,
  )

  if (workshopOrganizationField) {
    const organizations = []
    if (row['Organizations'] && row['Organization Recipient Types']) {
      const organizationsData = row['Organizations']
      const recipientTypes = row['Organization Recipient Types']
      const otherOrganizationTypes = row['Other Recipient Types']

      organizationsData?.forEach((organization, index) => {
        const recipientType = workshopOrganizationField.options.find(
          (option) =>
            option.label.trim().toLowerCase() === recipientTypes[index],
        )

        const otherRecipientTypePayload =
          otherOrganizationTypes[index] !== 'null'
            ? otherOrganizationTypes[index]
            : null
        const organizationData = {
          id: uuid4(),
          organisation: organization,
          recipientType: recipientType?.id,
          otherRecipientType: otherRecipientTypePayload,
        }
        organizations.push(organizationData)
      })
      delete row['Organization Recipient Types']
      delete row['Organizations']
      delete row['Other Recipient Types']
    }
    row.recipientsType = organizations
  }

  const sponsorOrganizationField = fields.find(
    (field) => field.sponsorOrganizationField,
  )
  if (sponsorOrganizationField) {
    const sponsors = []
    if (row['Sponsor Organization'] && row['Sponsor Type']) {
      const sponsorOrganizations = row['Sponsor Organization']
      const sponsorTypes = row['Sponsor Type']
      const otherSponsorTypes = row['Other Sponsor Type']

      sponsorOrganizations.forEach((sponsorOrganization, index) => {
        const sponsorOrganizationPayload = sponsorOrganization
        const sponsorTypePayload = sponsorOrganizationField.options.find(
          (option) => option.label.trim().toLowerCase() === sponsorTypes[index],
        )
        const otherSponsorTypePayload =
          sponsorTypes[index] === 'other specify' &&
          otherSponsorTypes[index].length
            ? otherSponsorTypes[index]
            : null

        const sponsor = {
          sponsorOrganisation: sponsorOrganizationPayload,
          sponsorType: sponsorTypePayload.id,
          otherSponsorType: otherSponsorTypePayload,
        }
        sponsors.push(sponsor)
      })

      delete row['Sponsor Type']
      delete row['Sponsor Organization']
    }
    row.sponsors = sponsors
  }

  const collaboratorPartnerLinkField = fields.find(
    (field) => field.collaboratorPartnerLinkField,
  )

  if (collaboratorPartnerLinkField) {
    const valueSet = new Set()
    if (row['Collaborators and Partners']) {
      const rowValues = row['Collaborators and Partners'].split('||')
      rowValues?.length > 0 &&
        rowValues.forEach((value) => {
          let splitValue = value.split('::')
          splitValue[0] =
            splitValue[0].trim().toLowerCase() === 'na'
              ? null
              : splitValue[0].trim().toLowerCase()
          splitValue[1] =
            splitValue[1].trim().toLowerCase() === 'na'
              ? null
              : splitValue[1].trim().toLowerCase()
          splitValue[2] =
            splitValue[2].trim().toLowerCase() === 'na'
              ? null
              : splitValue[2].trim().toLowerCase()
          splitValue[3] =
            splitValue[3].trim().toLowerCase() === 'na'
              ? null
              : moment(splitValue[3]).format('YYYY-MM')
          splitValue[4] =
            splitValue[4].trim().toLowerCase() === 'na'
              ? null
              : moment(splitValue[4]).format('YYYY-MM')
          let valueToHash = splitValue.join(' ').trim()

          const hashedValue = md5(valueToHash)
          const foundRecords =
            collaboratorPartnerLinkField.collaboratorRecords.filter(
              (record) => {
                let recordIndividual = record.title
                  ? record.title.trim().toLowerCase()
                  : null
                let recordOrganization = record.organization
                  ? record.organization.trim().toLowerCase()
                  : null
                let recordDepartment = record.department
                  ? record.department.trim().toLowerCase()
                  : null
                let recordStartDate = record.startDate
                  ? moment(record.startDate).format('YYYY-MM')
                  : null
                let recordEndDate = record.endDate
                  ? moment(record.endDate).format('YYYY-MM')
                  : null

                let recordToHash = [
                  recordIndividual,
                  recordOrganization,
                  recordDepartment,
                  recordStartDate,
                  recordEndDate,
                ]
                  .join(' ')
                  .trim()

                let hashedRecord = md5(recordToHash)

                return hashedValue === hashedRecord
              },
            )

          foundRecords &&
            foundRecords.length > 0 &&
            foundRecords.forEach((record) => {
              valueSet.add(record.id)
            })
        })

      row.partnerships = Array.from(valueSet)
    }
  }

  const keyCollaboratorsAndPartnerField = fields.find(
    (field) => field.keyCollaboratorsAndPartnerField,
  )

  if (keyCollaboratorsAndPartnerField) {
    const keyCollaboratorsAndPartners = []
    if (row['Individual'] && row['Organisation']) {
      const individual = row['Individual']
      const organisation = row['Organisation']

      individual.forEach((individual, index) => {
        const individualPayload = individual
        const organisationPayload = organisation[index]

        const keyCollaboratorsAndPartnersObj = {
          individual: individualPayload,
          organisation: organisationPayload,
        }
        keyCollaboratorsAndPartners.push(keyCollaboratorsAndPartnersObj)
      })
      row.keyCollaboratorsAndPartners = keyCollaboratorsAndPartners
      delete row['Individual']
      delete row['Organisation']
    }
  }

  if (
    endDateField &&
    endDateField.ongoingEnabled &&
    row['endDate'] === 'ongoing'
  ) {
    row['ongoing'] = true
    row['endDate'] = null
  }

  if (row?.supervisors?.length) {
    row.allSupervisors = row?.supervisors
  }

  return row
}

async function importRow(dispatch, actions, importer, importFn, row, fields?) {
  try {
    const importableRow = await importer.castForImport(row)
    importableRow.status = 'published'

    const importHash = md5(JSON.stringify(importableRow))
    await importFn(transformHierarchyRow(importableRow, fields), importHash)

    dispatch({
      type: actions.IMPORT_BATCH_SUCCESS,
      payload: {
        line: row._line,
      },
    })
  } catch (error) {
    dispatch({
      type: actions.IMPORT_BATCH_ERROR,
      payload: {
        line: row._line,
        errorMessage: Errors.selectMessage(error),
      },
    })
  }
}

export default (
  prefix,
  selectors,
  importFn,
  importFields,
  templateFileName,
  batchSize = 10,
) => {
  const actions = {
    RESET: `${prefix}_RESET`,

    FILE_READ_ERROR: `${prefix}_FILE_READ_ERROR`,
    FILE_READ_SUCCESS: `${prefix}_FILE_READ_SUCCESS`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
    IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,
    IMPORT_PAUSED: `${prefix}_IMPORT_PAUSED`,
    IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,

    IMPORT_BATCH_ERROR: `${prefix}_IMPORT_BATCH_ERROR`,
    IMPORT_BATCH_SUCCESS: `${prefix}_IMPORT_BATCH_SUCCESS`,

    doChangePagination: (pagination) => ({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    }),

    doChangeSort: (rows, sorter) => async (dispatch) => {
      const { field, order } = sorter

      let sortFn = (a, b) =>
        (String(a[field]) || '').localeCompare(String(b[field]) || '')

      if (field === '_line') {
        sortFn = (a, b) => a._line - b._line
      }

      if (field === '_status') {
        sortFn = (a, b) => (a._status || '').localeCompare(b._status || '')
      }

      let sortedRows = [...rows].sort(sortFn)

      if (order === 'descend') {
        sortedRows = sortedRows.reverse()
      }

      dispatch({
        type: actions.SORTER_CHANGED,
        payload: {
          sortedRows,
          sorter,
        },
      })
    },

    doReset: () => {
      return {
        type: actions.RESET,
      }
    },

    doPause: () => {
      return {
        type: actions.IMPORT_PAUSED,
      }
    },

    doImport: () => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.IMPORT_STARTED,
        })

        const pendingRows = selectors.selectPendingRows(getState())

        const importer = new Importer(importFields)

        const pendingBatches = chunk(pendingRows, batchSize)

        for (let batch of pendingBatches) {
          const paused = !selectors.selectImporting(getState())

          if (paused) {
            return
          }

          await Promise.all(
            batch.map((row) =>
              importRow(
                dispatch,
                actions,
                importer,
                importFn,
                row,
                importFields,
              ),
            ),
          )
        }

        dispatch({
          type: actions.IMPORT_SUCCESS,
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: actions.IMPORT_ERROR,
        })
      }
    },

    doDownloadTemplate: () => async () => {
      const importer = new Importer(importFields)
      importer.downloadTemplate(templateFileName, prefix)
    },

    doReadFile: (file) => async (dispatch) => {
      try {
        const importer = new Importer(importFields)

        let rawData = await importer.convertExcelFileToJson(file)

        if (!rawData || !rawData.length) {
          throw new Error(i18n('importer.errors.invalidFileEmpty'))
        }

        rawData = await Promise.all(
          rawData.map(async (row, index) => {
            return await importer.castForDisplay(row, index)
          }),
        )

        dispatch({
          type: actions.FILE_READ_SUCCESS,
          payload: rawData,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          type: actions.FILE_READ_ERROR,
          payload: error,
        })
      }
    },
  }

  return actions
}
