/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      researchTeamFiles: {
        id: 'researchTeamFiles',
        folder: 'tenant/:tenantId/researchTeam/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      researchTeamImages: {
        id: 'researchTeamImages',
        folder: 'tenant/:tenantId/researchTeam/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      researchThemesIntendedImpactFiles: {
        id: 'researchThemesIntendedImpactFiles',
        folder: 'tenant/:tenantId/researchThemesIntendedImpact/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      researchThemesIntendedImpactImages: {
        id: 'researchThemesIntendedImpactImages',
        folder: 'tenant/:tenantId/researchThemesIntendedImpact/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      currentProjectFiles: {
        id: 'currentProjectFiles',
        folder: 'tenant/:tenantId/currentProject/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      currentProjectImages: {
        id: 'currentProjectImages',
        folder: 'tenant/:tenantId/currentProject/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      collaborationPartnershipFiles: {
        id: 'collaborationPartnershipFiles',
        folder: 'tenant/:tenantId/collaborationPartnership/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      collaborationPartnershipImages: {
        id: 'collaborationPartnershipImages',
        folder: 'tenant/:tenantId/collaborationPartnership/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      externalDecisionMakingAdvisoryGroupFiles: {
        id: 'externalDecisionMakingAdvisoryGroupFiles',
        folder: 'tenant/:tenantId/externalDecisionMakingAdvisoryGroup/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      externalDecisionMakingAdvisoryGroupImages: {
        id: 'externalDecisionMakingAdvisoryGroupImages',
        folder: 'tenant/:tenantId/externalDecisionMakingAdvisoryGroup/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      providingExternalExpertiseFiles: {
        id: 'providingExternalExpertiseFiles',
        folder: 'tenant/:tenantId/providingExternalExpertise/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      providingExternalExpertiseImages: {
        id: 'providingExternalExpertiseImages',
        folder: 'tenant/:tenantId/providingExternalExpertise/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      collaborativeNetworkFiles: {
        id: 'collaborativeNetworkFiles',
        folder: 'tenant/:tenantId/collaborativeNetwork/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      collaborativeNetworkImages: {
        id: 'collaborativeNetworkImages',
        folder: 'tenant/:tenantId/collaborativeNetwork/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      guidelinePolicyPracticeFiles: {
        id: 'guidelinePolicyPracticeFiles',
        folder: 'tenant/:tenantId/guidelinePolicyPractice/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      guidelinePolicyPracticeImages: {
        id: 'guidelinePolicyPracticeImages',
        folder: 'tenant/:tenantId/guidelinePolicyPractice/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      consumerCommunityInvolvementFiles: {
        id: 'consumerCommunityInvolvementFiles',
        folder: 'tenant/:tenantId/consumerCommunityInvolvement/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      consumerCommunityInvolvementImages: {
        id: 'consumerCommunityInvolvementImages',
        folder: 'tenant/:tenantId/consumerCommunityInvolvement/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      oralPresentationFiles: {
        id: 'oralPresentationFiles',
        folder: 'tenant/:tenantId/oralPresentation/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      oralPresentationImages: {
        id: 'oralPresentationImages',
        folder: 'tenant/:tenantId/oralPresentation/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      reportFiles: {
        id: 'reportFiles',
        folder: 'tenant/:tenantId/report/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      reportImages: {
        id: 'reportImages',
        folder: 'tenant/:tenantId/report/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      toolsProductServiceFiles: {
        id: 'toolsProductServiceFiles',
        folder: 'tenant/:tenantId/toolsProductService/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      toolsProductServiceImages: {
        id: 'toolsProductServiceImages',
        folder: 'tenant/:tenantId/toolsProductService/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      awardPrizesFiles: {
        id: 'awardPrizesFiles',
        folder: 'tenant/:tenantId/awardPrizes/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      awardPrizesImages: {
        id: 'awardPrizesImages',
        folder: 'tenant/:tenantId/awardPrizes/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      mentoringSupervisionFiles: {
        id: 'mentoringSupervisionFiles',
        folder: 'tenant/:tenantId/mentoringSupervision/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      mentoringSupervisionImages: {
        id: 'mentoringSupervisionImages',
        folder: 'tenant/:tenantId/mentoringSupervision/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      caseStudyFiles: {
        id: 'caseStudyFiles',
        folder: 'tenant/:tenantId/caseStudy/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      caseStudyImages: {
        id: 'caseStudyImages',
        folder: 'tenant/:tenantId/caseStudy/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      communityConsumerLiteratureFiles: {
        id: 'communityConsumerLiteratureFiles',
        folder: 'tenant/:tenantId/communityConsumerLiterature/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      communityConsumerLiteratureImages: {
        id: 'communityConsumerLiteratureImages',
        folder: 'tenant/:tenantId/communityConsumerLiterature/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      fundingFiles: {
        id: 'fundingFiles',
        folder: 'tenant/:tenantId/funding/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      fundingImages: {
        id: 'fundingImages',
        folder: 'tenant/:tenantId/funding/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      barriersSupportFiles: {
        id: 'barriersSupportFiles',
        folder: 'tenant/:tenantId/barriersSupport/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      barriersSupportImages: {
        id: 'barriersSupportImages',
        folder: 'tenant/:tenantId/barriersSupport/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      editorialReviewerResponsibilityFiles: {
        id: 'editorialReviewerResponsibilityFiles',
        folder: 'tenant/:tenantId/editorialReviewerResponsibility/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      editorialReviewerResponsibilityImages: {
        id: 'editorialReviewerResponsibilityImages',
        folder: 'tenant/:tenantId/editorialReviewerResponsibility/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      educationTrainingMaterialFiles: {
        id: 'educationTrainingMaterialFiles',
        folder: 'tenant/:tenantId/educationTrainingMaterial/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      educationTrainingMaterialImages: {
        id: 'educationTrainingMaterialImages',
        folder: 'tenant/:tenantId/educationTrainingMaterial/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      interventionStudyFiles: {
        id: 'interventionStudyFiles',
        folder: 'tenant/:tenantId/interventionStudy/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      interventionStudyImages: {
        id: 'interventionStudyImages',
        folder: 'tenant/:tenantId/interventionStudy/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      inKindContributionFiles: {
        id: 'inKindContributionFiles',
        folder: 'tenant/:tenantId/inKindContribution/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      inKindContributionImages: {
        id: 'inKindContributionImages',
        folder: 'tenant/:tenantId/inKindContribution/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      mediaMentionFiles: {
        id: 'mediaMentionFiles',
        folder: 'tenant/:tenantId/mediaMention/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      mediaMentionImages: {
        id: 'mediaMentionImages',
        folder: 'tenant/:tenantId/mediaMention/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      patentFiles: {
        id: 'patentFiles',
        folder: 'tenant/:tenantId/patent/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      patentImages: {
        id: 'patentImages',
        folder: 'tenant/:tenantId/patent/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      publicationFiles: {
        id: 'publicationFiles',
        folder: 'tenant/:tenantId/publication/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      publicationImages: {
        id: 'publicationImages',
        folder: 'tenant/:tenantId/publication/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      testimonialFiles: {
        id: 'testimonialFiles',
        folder: 'tenant/:tenantId/testimonial/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      testimonialImages: {
        id: 'testimonialImages',
        folder: 'tenant/:tenantId/testimonial/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      workshopTrainingFacilitatedFiles: {
        id: 'workshopTrainingFacilitatedFiles',
        folder: 'tenant/:tenantId/workshopTrainingFacilitated/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      workshopTrainingFacilitatedImages: {
        id: 'workshopTrainingFacilitatedImages',
        folder: 'tenant/:tenantId/workshopTrainingFacilitated/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      intellectualPropertyFiles: {
        id: 'intellectualPropertyFiles',
        folder: 'tenant/:tenantId/intellectualProperty/files',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      intellectualPropertyImages: {
        id: 'intellectualPropertyImages',
        folder: 'tenant/:tenantId/intellectualProperty/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      evidenceFiles: {
        id: 'evidenceFiles',
        folder: 'tenant/:tenantId/evidenceFiles',
        maxSizeInBytes: 50 * 1024 * 1024,
      },
    }
  }
}
